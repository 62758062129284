import React, { Component, Fragment } from 'react'

export const formsEndpoint = process.env.GATSBY_FORMS_ENDPOINT || 'https://forms.carbfix.com'

const cookieData = {
  privacy: '',
  required: {
    required: true,
    links: ['']
  },
  data: {
    required: false,
    links: []
  },
  marketing: {
    required: false,
    links: []
  },
  settings: {
    required: false,
    links: []
  }
}

/**
 * component runs in two modes: static and dynamic 
 */
class OrFormsScripts extends Component {
  constructor (props) {
    super(props)

    this.state = {
      once: false
    }
  }

  shouldComponentUpdate () {
    return !this.state.once
  }

  componentDidMount () {
    if (this.props.dynamic && !this.state.once) {
      this.appendScriptsToHead()
    }

    this.setState({ once: true })
  }

  getLanguage () {
    return this.props.language || 'is'
  }

  urlFor (filename) {
    return `${formsEndpoint}/dist/${filename}?version=${Date.now()}`
  }

  getScripts () {
    const manifestExtScr = this.urlFor('js/manifest.js')
    const vendorExtScr = this.urlFor('js/vendor.js')
    const formExtScr = this.urlFor('js/kokuapp_onload.js')

    return [
      { url: manifestExtScr },
      { url: vendorExtScr },
      { url: formExtScr, dataUrl: formsEndpoint, dataLang: this.getLanguage() }
    ]
  }

  appendScriptsToHead () {
    const head = document.querySelector('head')

    this.getScripts()
      .map(this.createScript)
      .forEach(script => {
        head.appendChild(script)
      })
  }

  createScript ({ url, dataUrl, dataLang }) {
    let lf = document.createElement('script')
    lf.type = 'text/javascript'
    lf.async = false
    lf.src = url

    if (dataUrl) {
      lf.dataset.url = dataUrl
    }

    if (dataLang) {
      lf.dataset.lang = dataLang || 'is'
    }

    lf.dataset.cookieversion = '1'
    lf.dataset.cookie = JSON.stringify(cookieData)

    return lf
  }

  renderScripts () {
    return this.getScripts()
      .map(({ url, dataUrl, dataLang }) => (
        <CookieScript
          url={url}
          dataUrl={dataUrl}
          dataLang={dataLang} />
      ))
  }

  render () {
    return (
      <Fragment>
        <link rel='stylesheet' type='text/css' href={this.urlFor('css/kokuapp.css')} />
        <link rel='stylesheet' type='text/css' href={this.urlFor('css/komduividskipti.css')} />

        {!this.props.dynamic && this.renderScripts()}

      </Fragment>
    )
  }
}

const CookieScript = ({ url, dataUrl, dataLang }) => {
  const cookieLink = dataLang && dataLang === 'is' ? '/is/vafrakokur' : '/web-cookies'

  return (
    <script
      key={url}
      type='text/javascript'
      src={url}
      data-url={dataUrl}
      data-lang={dataLang || 'is'}
      data-cookieversion='1'
      data-cookie-link={cookieLink}
      data-cookie={JSON.stringify(cookieData)}
    />
  )
}

export default OrFormsScripts
