// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js": () => import("./../../../../node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/Directory/Directory.js" /* webpackChunkName: "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js" */),
  "component---src-components-sections-contact-card-section-contact-card-section-mdx": () => import("./../../../src/components/sections/ContactCardSection/ContactCardSection.mdx" /* webpackChunkName: "component---src-components-sections-contact-card-section-contact-card-section-mdx" */),
  "component---src-components-sections-featured-fluid-featured-fluid-mdx": () => import("./../../../src/components/sections/FeaturedFluid/FeaturedFluid.mdx" /* webpackChunkName: "component---src-components-sections-featured-fluid-featured-fluid-mdx" */),
  "component---src-components-sections-frontpage-image-frontpage-image-mdx": () => import("./../../../src/components/sections/FrontpageImage/FrontpageImage.mdx" /* webpackChunkName: "component---src-components-sections-frontpage-image-frontpage-image-mdx" */),
  "component---src-components-sections-hero-hero-mdx": () => import("./../../../src/components/sections/Hero/Hero.mdx" /* webpackChunkName: "component---src-components-sections-hero-hero-mdx" */),
  "component---src-components-sections-iframe-section-iframe-section-mdx": () => import("./../../../src/components/sections/IframeSection/IframeSection.mdx" /* webpackChunkName: "component---src-components-sections-iframe-section-iframe-section-mdx" */),
  "component---src-components-sections-image-comparison-image-comparison-mdx": () => import("./../../../src/components/sections/ImageComparison/ImageComparison.mdx" /* webpackChunkName: "component---src-components-sections-image-comparison-image-comparison-mdx" */),
  "component---src-components-sections-jump-link-section-jump-link-section-mdx": () => import("./../../../src/components/sections/JumpLinkSection/JumpLinkSection.mdx" /* webpackChunkName: "component---src-components-sections-jump-link-section-jump-link-section-mdx" */),
  "component---src-components-sections-jump-point-section-jump-point-section-mdx": () => import("./../../../src/components/sections/JumpPointSection/JumpPointSection.mdx" /* webpackChunkName: "component---src-components-sections-jump-point-section-jump-point-section-mdx" */),
  "component---src-components-sections-latest-news-latest-news-mdx": () => import("./../../../src/components/sections/LatestNews/LatestNews.mdx" /* webpackChunkName: "component---src-components-sections-latest-news-latest-news-mdx" */),
  "component---src-components-sections-link-section-link-section-mdx": () => import("./../../../src/components/sections/LinkSection/LinkSection.mdx" /* webpackChunkName: "component---src-components-sections-link-section-link-section-mdx" */),
  "component---src-components-sections-map-embed-map-embed-mdx": () => import("./../../../src/components/sections/MapEmbed/MapEmbed.mdx" /* webpackChunkName: "component---src-components-sections-map-embed-map-embed-mdx" */),
  "component---src-components-sections-map-map-mdx": () => import("./../../../src/components/sections/Map/Map.mdx" /* webpackChunkName: "component---src-components-sections-map-map-mdx" */),
  "component---src-components-sections-media-full-width-media-full-width-mdx": () => import("./../../../src/components/sections/MediaFullWidth/MediaFullWidth.mdx" /* webpackChunkName: "component---src-components-sections-media-full-width-media-full-width-mdx" */),
  "component---src-components-sections-news-footnote-news-footnote-mdx": () => import("./../../../src/components/sections/NewsFootnote/NewsFootnote.mdx" /* webpackChunkName: "component---src-components-sections-news-footnote-news-footnote-mdx" */),
  "component---src-components-sections-news-overview-news-overview-mdx": () => import("./../../../src/components/sections/NewsOverview/NewsOverview.mdx" /* webpackChunkName: "component---src-components-sections-news-overview-news-overview-mdx" */),
  "component---src-components-sections-qand-a-qand-a-mdx": () => import("./../../../src/components/sections/QandA/QandA.mdx" /* webpackChunkName: "component---src-components-sections-qand-a-qand-a-mdx" */),
  "component---src-components-sections-raft-link-section-raft-link-section-mdx": () => import("./../../../src/components/sections/RaftLinkSection/RaftLinkSection.mdx" /* webpackChunkName: "component---src-components-sections-raft-link-section-raft-link-section-mdx" */),
  "component---src-components-sections-rich-text-rich-text-mdx": () => import("./../../../src/components/sections/RichText/RichText.mdx" /* webpackChunkName: "component---src-components-sections-rich-text-rich-text-mdx" */),
  "component---src-components-sections-sales-card-section-sales-card-section-mdx": () => import("./../../../src/components/sections/SalesCardSection/SalesCardSection.mdx" /* webpackChunkName: "component---src-components-sections-sales-card-section-sales-card-section-mdx" */),
  "component---src-components-sections-search-field-search-field-mdx": () => import("./../../../src/components/sections/SearchField/SearchField.mdx" /* webpackChunkName: "component---src-components-sections-search-field-search-field-mdx" */),
  "component---src-components-sections-search-modal-search-modal-mdx": () => import("./../../../src/components/sections/SearchModal/SearchModal.mdx" /* webpackChunkName: "component---src-components-sections-search-modal-search-modal-mdx" */),
  "component---src-components-sections-service-steps-section-service-steps-section-mdx": () => import("./../../../src/components/sections/ServiceStepsSection/ServiceStepsSection.mdx" /* webpackChunkName: "component---src-components-sections-service-steps-section-service-steps-section-mdx" */),
  "component---src-components-sections-small-image-section-small-image-section-mdx": () => import("./../../../src/components/sections/SmallImageSection/SmallImageSection.mdx" /* webpackChunkName: "component---src-components-sections-small-image-section-small-image-section-mdx" */),
  "component---src-components-sections-solupunktar-solupunktar-mdx": () => import("./../../../src/components/sections/Solupunktar/Solupunktar.mdx" /* webpackChunkName: "component---src-components-sections-solupunktar-solupunktar-mdx" */),
  "component---src-components-sections-video-video-mdx": () => import("./../../../src/components/sections/Video/Video.mdx" /* webpackChunkName: "component---src-components-sections-video-video-mdx" */),
  "component---src-components-site-burger-burger-mdx": () => import("./../../../src/components/site/Burger/Burger.mdx" /* webpackChunkName: "component---src-components-site-burger-burger-mdx" */),
  "component---src-components-site-menu-mobile-menu-mobile-mdx": () => import("./../../../src/components/site/MenuMobile/MenuMobile.mdx" /* webpackChunkName: "component---src-components-site-menu-mobile-menu-mobile-mdx" */),
  "component---src-components-site-seo-seo-mdx": () => import("./../../../src/components/site/SEO/SEO.mdx" /* webpackChunkName: "component---src-components-site-seo-seo-mdx" */),
  "component---src-components-templates-animation-page-animation-page-js": () => import("./../../../src/components/templates/AnimationPage/AnimationPage.js" /* webpackChunkName: "component---src-components-templates-animation-page-animation-page-js" */),
  "component---src-components-templates-news-page-news-page-js": () => import("./../../../src/components/templates/NewsPage/NewsPage.js" /* webpackChunkName: "component---src-components-templates-news-page-news-page-js" */),
  "component---src-components-templates-page-page-js": () => import("./../../../src/components/templates/Page/Page.js" /* webpackChunkName: "component---src-components-templates-page-page-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

