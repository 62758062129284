import '@src/styles/index.scss'
import React, { Fragment } from 'react'
import OrFormsScripts from './src/components/site/OrFormsScripts'

export const wrapRootElement = ({ element, ...props }) => {
  return (
    <Fragment>
      {element}

      { process.env.NODE_ENV !== 'production' &&
        <OrFormsScripts dynamic />
      }
    </Fragment>
  )
}
